<template>
<div class="container">
    <a-layout id="components-layout-demo-top-side">
        <a-layout-header class="header">
            <companyheader></companyheader>
        </a-layout-header>

        <a-layout-content style="background-color: #ffffff">
<div class="cent_content">
    <div class="top_position">
        <span>当前位置:新闻详情</span>

    </div>
    <div class="title">
        <h3>{{data.Title}}</h3>
    </div>
    <div class="splitborder"></div>
    <p v-html="data.Content"></p>
    <img :src="data.ContentImage" />
    <div>


    </div>
</div>
        </a-layout-content>
    </a-layout>
</div>
</template>

<script>
    import companyheader from "./home/components/companyheader";
    export default {
        name: "companynews",
        components:{
            companyheader
        },
        data(){
            return {
                data:{}
            }
        },
        methods:{
            getDetail(){
                var num=this.$route.query.num;
                let that=this;
                that.data={};
                this.$axios.post("/api/news/detail",{num:num}).then((res) => {
                    if (res.data.Message=="SUCCESS"){
                        var result=res.data.Data;
                        that.data = result;
                    }
                });
            }
        },
        mounted(){
            this.getDetail();
        }
    }
</script>
<style>
    /* 适配各种屏幕尺寸 */
    @media (min-width: 1024px){
        body,html{font-size: 18px!important;}
    }
    @media (min-width: 1100px) {
        body,html{font-size: 20px!important;}
    }
    @media (min-width: 1280px) {
        body,html{font-size: 22px!important;}
    }
    @media (min-width: 1366px) {
        body,html{font-size: 24px!important;}
    }
    @media (min-width: 1440px) {
        body,html{font-size: 25px!important;}
    }
    @media (min-width: 1680px) {
        body,html{font-size: 28px!important;}
    }
    @media (min-width: 1920px) {
        body,html{font-size: 33px!important;}
    }

</style>
<style scoped lang="less">
    #components-layout-demo-top-side .header{
        height: 2.76rem;
        background-color: #fff;
        box-shadow: 0px 0px 0.42rem 0px rgba(0, 0, 0, 0.18);


        box-sizing: border-box;
        padding: 0.63rem 1.78rem 0.6rem 1.7rem;
    }

    .cent_content{
        background-color: #F9F9F9;
        width: 1100px;
        margin: 0 auto;
        .top_position{
        span{
            color: #121212;
            font-size: 14px;
        }
        }
        .title{
            height: 86px;
            width: 100%;
            text-align: center;
            vertical-align: center;
            h3{
            color: #121212;
                font-size: 30px;
            }
        }
        .splitborder{
            width: 100%;
            margin: 0 2px;
            height: 2px;
            background-color: #CBCBCB ;
        }
        p{
            margin-top: 73px;
            margin-left:40px ;
            margin-right: 40px;
            color: #121212;
            font-size: 26px;
        }
        img{
            width: 100%;
            padding: 20px;

        }

    }

</style>
